.UpgradeSection{
    padding-left: 260px;
}

@media (max-width: 768px) {
    .UpgradeSection{
        padding-left: 0px;
    }   
    .SubscribePlanSecondDiv{
        width: 100%;
        min-height: auto;
        
    }
    .floatDiv .SubscribePlanSecondDiv{
        margin: 10px 0;
    }
    .floatDiv{
        margin: -200px 15px 0 15px;
        flex-direction: column;
    }
    .SubscribePlanSecond{
        min-height: auto;
    }
    
}