footer{
    width: 100%;
    background-color: #fff;
    padding: 24px 0 32px;
    color: #000;
    box-shadow: 0 0 10px grey;
}
.footerSize{
    display: flex;
    justify-content: space-between;
}
.Copyright{
    font-size: 14px;
    opacity: 0.5;
}
.footerRight{
    display: flex;
    color: #221F1F;

}
.footerRightBox{
    margin-left: 100px;
}

.footerRightBox__link {
    text-decoration: none;
    color: #221f1f;
}

.footerRightBox h6{
    font-family: PoppinsSemiBold;
    font-size: 16px;
    margin-bottom: 22px;
}
.footerRightBox p{
    margin-bottom: 10px;
    font-size: 16px;
}
.footersocs{
    display: flex;
}
.footersocs img{
    margin: 0px 8px;
    cursor: pointer;
}
.for-mobile{
    display: none;
}

@media (max-width: 576px) {
    .footer-top img{
        width: 150px;
    }
    .footerRight{
        width: 100%;
        display: block;
    }

    .footerSize img{
        margin: 0 10px !important;
    }
    .footersocs{
        justify-content: center !important;
    }
    .footerRight .footerRightBox:not(:last-child){
        display: none;
    }
    .footer-top p{
        display: none;
    }
    .for-mobile{
        display: block;
    }
    .footerRight{
        margin: 20px 0 25px 0 !important;
    }
    footer{
        padding-bottom: 15px;
    }
    .footerRightBox h6{
        margin-bottom: 10px;
    }
}