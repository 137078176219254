.example2sec{
    padding-left: 260px;
    padding-top: 30px;
    flex-direction: column;
    display: flex;
    align-items: center;
}
.btnAreaDiv{
    display: flex;
    flex-direction: column;
}
.step4btn{
    border: 1px solid #FF9900;
    border-radius: 8px;
    opacity: 1;
    background-color: white;
    color: #FF9900;
    justify-content: flex-start;
}
.step4btn p {
    margin-left: 12px;
    cursor: pointer !important;
}
.step4img{
    width: 25px;
    height: 25px;
    background-image: url('../../assets/images/btndownload.png');
}
.StegoExtract{
    margin-left: 950px !important;
    margin-top: -300px !important;
}
.stegoWarningExtract{
    padding-left: 292px;
    padding-top: 30px;
}

