.ContactFormMain{
    display: flex;
    flex-direction: column;
}
.ContactFormMain h1{
    font-size: 40px;
    color: #FFFFFF;
    margin-bottom: 32px;
}
.ContactFormMain p {
    font-size: 20px;
    color: #FFFFFF;
    width: 579px;
    margin-bottom: 24px;
}
.ContactFormInfo{
    display: flex;
    width: 664px;
    justify-content: space-between;
    margin-bottom: 24px;
}
.ContactFormInfo input {
    height: 55px;
    width: 320px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding-left: 16px;
    background-color: #FF9900;
    color: #FFFFFF;
    font-size: 20px;
}
.ContactFormSubject{
    height: 55px;
    width: 664px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding-left: 16px;
    background-color: #FF9900;
    color: #FFFFFF;
    font-size: 20px;
    margin-bottom: 24px;
}
.ContactFormMessage{
    height: 200px;
    width: 664px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding-left: 16px;
    background-color: #FF9900;
    color: #FFFFFF;
    font-size: 20px;
    margin-bottom: 24px;
    padding-top: 14px;
    resize: none;
}
.ContactFormButton{
    width: 664px;
    height: 55px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    color: #FF9900;
    font-weight: bold;
    font-size: 22px;
}
.ContactFormMain ::placeholder{
    color: #FFFFFF;
}

.submitMessage{
    margin-top: 18px;
    font-weight: bold;
}