.WinStegDropArea{
    width: 100%;
    height: 500px;
}
.WinStegErrorMsg{
    margin-top: 16px;
    font-weight: bold;
    color: red;
}
.WinStegPlaceHolder{
    margin-left: 16px;
    margin-top: 10px;
    color: #FF9900;
}