.MyAccountSection{
    margin-top: 32px;
    padding-left: 292px;
}
.MyAccountAll{
    display: flex;
}
.MyAccountData{
    margin-left: 24px;
}
.MyAccountDataSlash{
    border: 1px solid #B2B2B2;
    width: 80px;
    height: 0px;
}
.MyAccountData > *{
    padding-bottom: 12px;
}
.MyAccountDataHandleSection > p {
    text-decoration: underline;
    cursor: pointer;
}
.MyAccountImg{
    border-radius: 4px;
    width: 160px;
    height: 160px;
}
.MyAccountAddImg{
    color: #FF9900;
    position: absolute;
    margin-left: 68px;
    margin-top: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid #FF9900;
    background-color: white;
    border-radius: 50%;
}
.MyAccountFullName{
    font-size: 24px;
    font-weight: bold;
    color: #221F1F;
}
.MyAccountDataChangePassword{
    margin-left: 24px;
}
.MyAccountOpenedSlash{
    border: 1px solid #B2B2B2;
    height: 0px;
    width: 450px;
    margin-top: 18px;
}
.EditPersonalInfo{
    margin-top: 20px;
    color:  #FF9900;
    font-size: 18px;
}
.MyAccountInput{
    width: 340px;
    margin-bottom: 0;
}
.MyAccountEmail{
    margin-top: 12px;
}
.MyAccountPhone{
    margin-top: 20px;
}
.MyAccountHandleButtons{
    margin-top: 24px;
    display: flex;
}
.MyAccountHandleButtons{
    display: flex;
    justify-content: space-between;
    width: 340px;
}
.MyAccountButton1{
    width: 158px;
    color: #FF9900;
    background-color: white;
    border: 1px solid #FF9900;
}
.MyAccountButton2{
    width: 158px;
}
.errorMessage2{
    margin-top: 12px;
}
.MyAccountPopup{
    position: absolute;
    width: 500px;
    height: 200px;
    box-shadow: 10px 10px 20px rgba(112, 109, 109, 0.102);
    background-color: white;
    border-radius: 20px;
}
.MyAccountPopupP1{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
}
.MyAccountPopupP2{
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}
.MyAccountPopupSelect{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 45px;
}
.MyAccountPopupSelect p {
    cursor: pointer;
}
.MyAccountPopupSelect p:hover{
    transition: 1s;
    color: #FF9900;
}
.MyAccoutAll{
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(112, 109, 109, 0.502);
    justify-content: center;
    align-items: center;
}
.MyAccountAllNone{
    display: none !important;
}

@media (max-width: 768px) {
    .MyAccountSection{
        padding-left: 30px;
    }
    /* .sidebar-open .MyAccountSection{
        padding-left: 292px;
    } */
}