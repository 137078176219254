.loader {
  flex: 1;
  height: 90vh;
  display: grid;
  place-content: center;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid orange;
  border-top-color: #ffffff;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
