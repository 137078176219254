.paymentBox{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.paymentTitle{
    font-weight: 700;
    font-size: 40px;
}

.paymentBox .loader-container {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 300px;
    margin-bottom: 40px;
  }

  .paymentBox .loader-icon {
    width: 100px;
    height: 100px;
    background: #f90;
    transform-style: preserve-3d;
    animation: loading 2.5s ease infinite;
    -webkit-animation: loading 2.5s ease infinite;
  }
  
  @-webkit-keyframes loading {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: rotateX(180deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(180deg) rotateY(180deg);
    }
  }
  
  @keyframes loading {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: rotateX(-180deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(-180deg) rotateY(180deg);
    }
  }
  