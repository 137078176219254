header {
  width: 100%;
  height: 78px;
  box-shadow: 10px 10px 20px #0000001a;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.HeaderPart1 {
  display: flex;
}
.HeaderPart2 {
  display: flex;
  align-items: center;
}
.HeaderPart1 .active {
  border-bottom: 1px solid black;
}
.headerSize {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerLogo {
  cursor: pointer;
}
.headerRight {
  display: flex;
  align-items: center;
  font-family: PoppinsSemiBold; 
}
.headerRight * {
  font-size: 16px;
  color: #221f1f;
  margin-left: 32px;    
  cursor: pointer;
}
.headerLogin {
  color: #ff9900;
}
.headerRight .signUpBtn {
  font-family: PoppinsSemiBold; 
  width: 109px;
  height: 46px;
  background: rgba(255, 153, 0, 0.2);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.signUpBtn span {
  margin: 0;
  color: #ff9900;
}
.Header2 {
  width: 100%;
  padding-left: 292px;
  height: 73px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px #0000001a;
  position: fixed;
  background-color: white;
  z-index: 20;
}
.Header2 p {
  text-transform: capitalize;
  font-size: 18px;
  font-family: PoppinsMedium; 
}
.switchIcon {
  margin-left: 16px;
  border-radius: 11px;
  opacity: 0.3;
}
.p2 {
  margin-left: 16px;
}

.sidetoggle-open {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  display: none;
  position: relative;
}
.sidetoggle-open svg {
  width: 28px;
  height: 28px;
}

.sidebar-open::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 25;
}

@media (max-width: 768px) {
  .sidetoggle-open {
    display: block;
  }
  .Header2 {
    padding-left: 30px;
  }
  /* .sidebar-open .Header2{
        padding-left: 292px;
    } */
  .sidebar-open .sidetoggle-open {
    display: none;
  }
}
