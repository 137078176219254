.sliderdiv{
    margin: 0 auto;
    display: flex;
    width: 251px;
    justify-content: space-between;
    align-items: center;
}
.slider{
    margin-right: 0;
    border: 1px solid #FF9900;
    color: #FF9900;
    background-color: white;
}
.line{
    width: 64px;
    height: 0px;
    border: 1px dashed #FF9900;
}
.MixFilesLoading{
    position: absolute;
    margin-left: 275px;
    margin-top: -416px;
}
.StegoNamePopup{
    border: 1px solid #FF9900;
    margin: 31vh 31vw !important;
}
.StegoName{
    border: 1px solid #FF9900;
    height: 43px;
    padding: 10px;
    border-radius: 16px;
    font-weight: bold;
    margin-left: 157px;
    margin-top: 19px;
}
.StegoPopupSelect{
    margin-top: 20px !important
}
.sameNameError{
    width: 381px;
}
.stegoWarning{
    padding-left: 32px;
}
.stegoWarning p {
    font-weight: bold;
    font-size: 24xp;
}