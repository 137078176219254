.ContactUsMain{
    background-color: #FF9900;
    display: flex;
    height: 687px;
}
.ContactUsFirst{
    width: 58%;
}
.ContactUsSecond{
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/contactuspic.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}
.ContactUsSecond img {
    width: 543px;
    height: 479px;
}
.ContactUsForm{
    margin-left: 170px;
}

@media (max-width: 1366px) {
    .ContactFormMain h1{
        margin-left: 0;
    }
    .ContactUsMain{
        padding: 50px 0 0 0;
    }
    .ContactUsSecond img{
        width: 400px;
        height: auto;
    }
    .ContactFormInfo,
    .ContactFormSubject,
    .ContactFormMessage,
    .ContactFormButton{
        width: 600px;
    }
    .ContactFormInfo input{
        width: 290px;
    }
}
@media (max-width: 1024px) {
    .ContactUsForm{
        margin-left: 70px;
    }
    .ContactFormMain h1{
        font-size: 34px;
        margin-bottom: 20px;
        line-height: 35px;
    }
    .ContactFormInfo,
    .ContactFormSubject,
    .ContactFormMessage,
    .ContactFormButton{
        width: 450px;
    }
    .ContactFormInfo input{
        width: 210px;
    }
}

@media (max-width: 768px) {
    .ContactUsSecond{
        display: none;
    }
    .ContactUsFirst {
        width: 100%;
    }
    .ContactUsForm{
        margin: 0 auto;
        max-width: 640px;
        padding: 0 30px;
    }
    .ContactFormInfo, .ContactFormSubject, .ContactFormMessage, .ContactFormButton{
        width: 100%;
    }
    .ContactFormInfo input{
        width: 48%;
    }
    .ContactFormMain p{
        width: 100%;
    }
    .ContactFormInfo input,
    .ContactFormSubject,
    .ContactFormMessage{
        font-size: 16px;
    }
}
@media (max-width: 576px) {
    .ContactFormMain h1{
        font-size: 28px;
        margin-bottom: 10px;
    }
    .ContactFormMain p{
        font-size: 16px;
    }
    .ContactFormButton{
        font-size: 18px;
    }
}