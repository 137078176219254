.leftBar {
  width: 260px;
  height: 984px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  position: fixed;
  padding-top: 12px;
  padding-left: 36px;
  z-index: 30;
}
.leftBarList {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: border-right 0.3s;
}
.leftBar a p {
  cursor: pointer;
}
.leftBarList p {
  margin-left: 12px;
  color: #221f1f;
  font-size: 15px;
  font-family: PoppinsMedium;
}

.leftBarLogo {
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #968484;
}
.leftBarListLine {
  width: 50%;
  height: 1px;
  background-color: #968484;
  margin-bottom: 20px;
}
.LeftBarMyAccountPopup {
  height: 140px !important;
}

.divall {
  width: 34px;
  height: 34px;
}
.leftbardiv1 {
  background-image: url("../../assets/images/leftBar/1.png");
}
.leftbardiv2 {
  background-image: url("../../assets/images/leftBar/2.png");
}
.leftbardiv3 {
  background-image: url("../../assets/images/leftBar/3.png");
}
.leftbardiv4 {
  background-image: url("../../assets/images/leftBar/4.png");
}
.leftbardiv5 {
  background-image: url("../../assets/images/leftBar/5.png");
}
.leftbardiv6 {
  background-image: url("../../assets/images/leftBar/6.png");
}
.leftbardiv7 {
  background-image: url("../../assets/images/leftBar/7.png");
}
.leftbardiv8 {
  background-image: url("../../assets/images/leftBar/8.png");
}
.leftbardiv9 {
  background-image: url("../../assets/images/leftBar/9.png");
}
.leftbardiv10 {
  background-image: url("../../assets/images/leftBar/10.png");
}
.leftbardiv12 {
  background-image: url("../../assets/images/leftBar/12.png");
}

.leftBar .active {
  border-right: 5px #ff9900 solid;
}
.active .leftbardiv1 {
  background-image: url("../../assets/images/leftBar/1_hover.png");
}
.active .leftbardiv2 {
  background-image: url("../../assets/images/leftBar/2_hover.png");
}
.active .leftbardiv3 {
  background-image: url("../../assets/images/leftBar/3_hover.png");
}
.active .leftbardiv4 {
  background-image: url("../../assets/images//leftBar/4_hover.png");
}
.active .leftbardiv5 {
  background-image: url("../../assets/images/leftBar/5_hover.png");
}
.active .leftbardiv6 {
  background-image: url("../../assets/images/leftBar/6_hover.png");
}
.active .leftbardiv7 {
  background-image: url("../../assets/images/leftBar/7_hover.png");
}
.active .leftbardiv8 {
  background-image: url("../../assets/images/leftBar/8_hover.png");
}
.active .leftbardiv9 {
  background-image: url("../../assets/images/leftBar/9_hover.png");
}
.active .leftbardiv10 {
  background-image: url("../../assets/images/leftBar/10_hover.png");
}
.active .leftbardiv12 {
  background-image: url("../../assets/images/leftBar/12_hover.png");
}

.leftBarList:hover {
  border-right: 5px #ff9900 solid;
}

.sidetoggle-close {
  text-align: right;
  margin-right: 10px;
  position: absolute;
  right: 0px;
  display: none;
}

.sidetoggle-close svg {
  width: 30px;
  height: 30px;
}

.cancel-sub .divall {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-sub .divall svg {
  width: 22px;
  height: 22px;
}

@media (max-width: 768px) {
  .sidetoggle-close {
    display: block;
  }
  .leftBar {
    transform: translateX(-300px);
    transition: 0.5s;
  }
  .sidebar-open .leftBar {
    transform: translateX(0px);
  }
}
