.modal {
  justify-content: center;
  align-items: center;
  position: fixed;
  display: block;
  inset: 0;
}

.modal::before {
  background-color: rgba(112, 109, 109, 0.502);
  position: fixed;
  content: "";
  inset: 0;
}

.modal .modal_content {
  position: relative;
  max-width: 500px;
  padding: 40px;
  color: #333;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal .modal_content .m-content {
  background-color: #fff;
  padding: 30px 25px 25px 25px;
  text-align: center;
  position: relative;
}

.m-close {
  font: normal normal 600 18px/27px Poppins;
  color: white;
  background: #ff9900 0% 0% no-repeat padding-box;
  width: 268px;
  height: 46px;
  border-radius: 8px;
  opacity: 1;
  margin-top: 25px;
}

.m-close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  cursor: pointer;
  transition: 0.5s;
}
.m-close-icon svg {
  width: 25px;
  height: 25px;
}
.m-close-icon:hover {
  color: #ff9900;
}

.modal .modal_content.video-modal {
  max-width: 1000px;
}

.react-player {
  width: 100% !important;
  height: 100% !important;
  margin-left: 0;
}
.react-player video {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .v-modal::before {
    display: none;
  }
  .v-modal {
    top: 30%;
  }
}

@media screen and (max-width: 414px) {
  .v-modal .modal_content .m-content {
    padding: 15px !important;
  }
  .v-modal .m-close-icon {
    top: 0;
    right: 0;
  }
  .v-modal .m-close-icon svg {
    width: 20px;
    height: 20px;
  }
}
