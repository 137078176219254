p {
  cursor: default;
}

.home {
  background-color: #ff9900;
  color: #ffffff;
  overflow-x: hidden;
}

.homeSection1 {
  width: 100%;
  background-image: url(../../assets/images/home/bg.webp);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.homeSize {
  /* display: flex;
    align-items: flex-end; */
}
.banner-bottom {
  display: flex;
  justify-content: space-between;
}

.homeSection1LeftTitle {
  text-transform: capitalize;
  font-family: PoppinsSemiBold;
  font-size: 110px;
  margin-top: 200px;
  margin-left: 0;
  text-align: center;
}

.homeSection1LeftText {
  text-transform: capitalize;
  font-size: 56px;
  margin: 12px 0 32px;
      text-align: center;
}

.homeSection1Btns {
  display: flex;
  margin-bottom: 242px;
}

.homeSection1Btn1 {
  width: 241px;
  height: 79px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ff9900;
  text-transform: capitalize;
  /* font-family: PoppinsSemiBold; */
  font-size: 22px;
}

.homeSection1Btn2 {
  background: transparent !important;
  color: #fff;
  border: 1px solid #fff;
  margin-left: 32px;
}

.homeSection1LeftBottomTop {
  display: flex;
}

.homeSection1LeftBottomTop p {
  text-transform: capitalize;
  font-size: 40px;
  margin-right: 60px;
}

.homeSection1LeftBottomTitle {
  text-transform: capitalize;
  font-size: 40px;
  margin-bottom: 32px;
    text-align: center;
}

.homeSection1LeftBottomText {
  width: 502px;
  font-size: 20px;
}

.homeSectionSize {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeSectionSize2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.homeSection2 {
  padding: 173px 0 211px;
}

.homeSectionsTitle {
  font-family: PoppinBold;
  text-transform: capitalize;
  font-size: 40px;
  text-align: left;
}

.homeSectionsText {
  font-size: 20px;
  margin: 32px 0 24px;
  text-align: left;
}

.homeSectionsBtns {
  width: 241px;
  height: 55px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ff9900;
  text-transform: capitalize;
  font-size: 22px;
  /* font-family: PoppinsSemiBold; */
}

.homeSection3Bg {
  /* padding-bottom: 377px; */
  background-image: url(../../assets/images/home/img5.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.homeSection8 {
  background-image: url(../../assets/images/home/img12.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.homeSection2Side {
  width: 502px;
  text-align: right;
}

.homeSection2Side2 {
  width: 668px;
  text-align: left;
}

.dontShow {
  display: none;
}

.imgHello {
  object-fit: cover;
  height: 488px;
  width: 696px;
}

.mobile-menu {
  width: 30px;
  height: 30px;
}
.mobile-menu svg {
  margin-left: 0;
  width: 30px;
  height: 30px;
}

.mobile-menu.for-close {
  display: none;
}

.mobile-menu.for-open {
  display: none;
}

.menu-open .mobile-menu.for-close {
  display: block;
}

.menu-open .mobile-menu.for-open {
  display: none;
}

.HeaderPart1 .m-link {
  display: none;
}

@media (max-width: 1366px) {
  .headerRight * {
    margin-left: 20px;
  }
  .homepage .section-img {
    width: 500px;
  }
  .imgHello {
    height: 380px;
    width: 515px;
  }
  .homeSection1LeftTitle {
    font-size: 60px;
    margin-top: 90px;
    margin-left: 0;
  }
  .homeSection1LeftText {
    font-size: 40px;
  }
  .homeSection1LeftBottomTitle {
    font-size: 30px;
  }
  .homeSection1Btns {
    margin-bottom: 150px;
  }
  .homeSection2 {
    padding: 150px 0px;
  }
  .dontShow {
    display: block;
  }
}

@media (max-width: 1024px) {
  .homeSection1LeftTitle {
    font-size: 40px;
    margin-top: 50px;
  }
  .homeSection1LeftText {
    font-size: 25px;
    margin-top: 0;
  }
  .homeSection1LeftBottomTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .homeSection1Btn1,
  .homeSectionsBtns {
    width: auto;
    height: auto;
    font-size: 18px;
    padding: 10px 20px;
  }
  .homepage .section-img {
    width: 400px;
  }
  .section-wrap {
    display: flex;
  }
  .section-wrap .section-img {
    width: 50%;
    margin: 0 15px;
  }
  .section-wrap .section-text {
    width: 50%;
    margin: 0 15px;
  }
  .section-wrap .section-img.small-img {
    width: 100%;
  }
  .homeSectionsTitle {
    font-size: 30px;
    line-height: 40px;
  }
  .homeSection2 {
    padding: 100px 0px;
  }
}

@media (max-width: 768px) {
  .HeaderPart1 {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    top: 0;
    border-top: 1px solid #ccc;
    z-index: -1;
    transition: 0.5s;
  }
  .menu-open .HeaderPart1 {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    top: 100%;
    margin-left: 0;
  }
  .HeaderPart1 .m-link {
    display: block;
  }
  .HeaderPart1 a,
  .HeaderPart1 p {
    margin-left: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
  }
  .section-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .homeSection1 {
    background-image: none;
  }
  .homeSection1Btns {
    margin-bottom: 90px;
  }
  .section-wrap .section-text {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .imgHello,
  .section-wrap .section-img {
    margin: 0 auto;
  }
  .section-wrap img {
    margin: 0 auto;
  }
  .homeSection2 {
    padding: 60px 0px;
  }
  .homeSectionsTitle {
    font-size: 24px;
  }
  .homeSectionsText {
    font-size: 18px;
    margin: 10px 0px 22px;
  }
  .homeSection5 img {
    width: 40%;
  }
  .footerRightBox {
    margin-left: 0px;
  }
  .footerSize {
    flex-direction: column;
  }
  .footerSize div {
    text-align: center;
    justify-content: space-between;
  }
  .footerSize img {
    margin: 0 auto;
  }
  .footerRight {
    margin-top: 40px;
  }
  .banner-bottom {
    flex-direction: column-reverse;
    width: 100%;
  }
  .section-wrap .section-img.small-img {
    width: 400px;
  }
  .banner-bottom .right {
    margin: 0 auto;
  }
  .banner-bottom .left img {
    display: none;
  }
  .homeSection1LeftBottomTop {
    display: block;
  }
  .homeSection1LeftBottomText {
    width: 100%;
  }
  .homeSection1LeftBottomTop p {
    margin-right: 0;
  }
  .homeSection1Left {
    text-align: center;
    width: 100%;
  }
  .homeSection1Btns {
    justify-content: center;
  }
  .mobile-menu.for-open {
    display: block;
    margin-left: 15px;
  }
}
@media (max-width: 576px) {
  .homeSection1LeftTitle {
    font-size: 28px;
  }
  .homeSection1LeftText {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .homeSection1LeftBottomTitle {
    font-size: 15px;
    padding: 0 40px;
  }
  .homeSection1Btn1,
  .homeSectionsBtns {
    font-size: 14px;
  }
  .homeSection1LeftBottomTop p,
  .homeSectionsTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 20px;
  }
  .homeSection1LeftBottomText,
  .homeSectionsText {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .section-wrap .section-text {
    margin: 10px 0 0 0;
  }
  .imgHello {
    height: 245px;
    width: 345px;
  }
  .homeSection2 {
    padding: 0;
    margin-bottom: 80px;
  }
  .banner-bottom {
    margin-bottom: 80px;
  }
}
@media (max-width: 360px) {
  .section-wrap .section-img.small-img {
    width: 280px;
  }
  .homeSection1LeftBottomText,
  .homeSectionsText {
    padding: 0 20px;
  }
  .headerRight * {
    margin-left: 7px;
    font-size: 14px;
  }
  .headerRight .signUpBtn {
    width: 80px;
    height: 35px;
  }
}
