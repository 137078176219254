.ocrcont{  
    margin-top: 32px;
    margin-left: 292px;
    margin-right: 30px;
}
.recordMessage2{
    margin-left: 73px;
}
.warningMsg{
    font-weight: bold;
    color: #FF0000;
    font-size: 14px;
    margin-bottom: 7px;
}