.RP{
    margin-top: 18px;
}
.errorMessageNew{
    font-weight: bold;
    color: red;
    text-align: center;
}
.successMessageNew{
    font-weight: bold;
    color: green;
    text-align: center;
}

.container {
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .box {
    box-shadow: -4px 10px 35px -1px rgba(0, 0, 0, 0.75);
    min-height: 20rem;
    margin: 30px;
    padding: 20px;
    border-radius: 5px;
  }
  
  h1 {
    margin-left: 20px;
  }
  
  button {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background: transparent;
  }