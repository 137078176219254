.textToSpeachContent{
   margin-top: 16px;
   margin-left: 292px;
   margin-right: 30px;
}
.textToSpeachContentHeader{
   display: flex;
   padding-bottom: 24px;
}
.tdiv{
   display: flex;
   flex-direction: column;
   padding-right: 32px;
}
.tdiv p{
   padding-bottom: 6px;
   font-weight: bold;
}
.sel{
   padding-left: 16px;
   width: 340px;
   height: 46px;
   border: 1px solid #B2B2B2;
   border-radius: 8px;
   opacity: 1;
}
.mid{
   width: 100%;
   display: flex;
   align-items: center;
   border: 1px dashed #B2B2B2;
   border-radius: 16px;
   opacity: 1;
   cursor: default;
   margin-top: 5px;
}
.TTSconverting{
    position:fixed;
    margin-left: 400px;
    margin-top: -100px;
}
.revImg{
   margin-left: 40%;
}
.midp{
   font-weight: bold;
   margin: 24px 32px;
}
span{
   color:#FF9900
}
.recordMessage{
   opacity: 0.3;
   margin-left: 12px;
}
.sound-wave{
   margin-left: 12px;
}
.uploadedFileName{
   margin-left: 18px;
   font-weight: bold;
   color: green;
}
.TTSCheckBox p{
   margin-left: 12px;
   color: #FF9900;
   cursor: pointer;
   text-decoration: underline;
}
.TTSAudio{
   margin-left: 283px;
   margin-top: 22px;
}
#audio-canvas{
   margin-left: 30px;
}
.tdiv2{
   margin-top: 24px;
   padding-bottom: 12px;
}
.checkLang{
   text-decoration: underline;
   width: 340px;
}
.checkLang:visited{
   color: black;
}
.downloadWav{
   margin-left: 292px;
   width: 100px;
   cursor: pointer;
   text-transform: capitalize;
   color: #FF9900;
   text-decoration: underline;
}

@media (max-width: 1280px) {
   .sound-wave{
      width: 470px;
   }
   .smallWindow{
      margin-bottom: 30px;
   }
}

@media (max-width: 1024px) {
   .sound-wave{
      width: 300px;
   }
}

@media (max-width: 768px) {
   .mid{
      flex-direction: column;
      padding: 10px;
   }
   .midp{
      margin: 0px 0px 12px 0;
   }
   .recordMessage{
      margin-left: 0;
   }
   .sound-wave{
      margin-left: 0;
      height: 90px;
   }
   .tdiv{
      padding-right: 0;
   }
   .sel{
      width: 100%;
   }
   .textToSpeachContentHeader{
      display: block;
   }
   .textToSpeachContent,
   .ocrcont,
   .TranslateSection{
      text-align: center;
      margin-left: 30px !important;
   }
   
   .textToSpeachContentHeader .sel{
      width: 100%;
   }
   .tdiv .sel{
      width: 100%;
   }

   /* .sidebar-open .textToSpeachContent,
   .sidebar-open .ocrcont,
   .sidebar-open .TranslateSection{
      margin-left: 292px !important;
   } */

   .revImg{
      margin-left: 0;
   }
   
   .smallWindow .content{
      margin: 15px;
   }
   .smallWindow .content .speechText{
      background-color: transparent;
   }
   .smallWindow  .swG > div:first-child{
      margin: 0 15px;
   }

}

@media (max-width: 360px) {
   .sound-wave{
      width: 180px;
      height: 90px;
   }
}
