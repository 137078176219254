.SubscribePlanMain {
  display: flex;
  flex-direction: column;
}
.SubscribePlanFirst {
  width: 100%;
  height: 500px;
  background-color: #ff9900;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SubscribePlanSecond {
  width: 100%;
  min-height: 900px;
}
.SubscribePlanFirst h1 {
  font-size: 40px;
  margin-top: 64px;
  color: #fff;
  font-weight: bold;
}
.SubscribePlanFirst p {
  color: #fff;
  font-size: 20px;
}
.SubscribePlanSwitch {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.SubscribePlanSwitch p {
  font-size: 23px;
}
.floatDiv {
  display: flex;
  margin-left: 274px;
  margin-top: -200px;
}

.freeDiv span {
  color: #b2b2b2;
}
.SubscribePlanSecondDiv {
  width: calc(100% / 3 - 30px);
  margin: 0 15px;
  min-height: 808px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 32px;
  transition: all 1s ease;
  border: 1px solid #fff;
}
.SubscribePlanSecond .floatDiv {
  margin: -200px 200px 0 200px;
  justify-content: center;
}
.freeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  border-bottom: 1px solid #b2b2b2;
  flex-direction: column;
}
.freeDiv p {
  font-size: 32px;
  font-weight: bold;
}
.Lists {
  padding-top: 32px;
}
.Lists div {
  margin-bottom: 12px;
}
.SubscribePlanBtnDiv {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.SubscribePlanBtnDiv button {
  width: 241px;
  height: 55px;
  border: 1px solid #221f1f;
  border-radius: 8px;
  opacity: 1;
  background-color: #fff;
  font-size: 22px;
  font-weight: bold;
  transition: all 1s ease;
}
.SubscribePlanBtnDiv button:disabled {
  color: #221f1f;
}

.SubscribePlanSecondDiv:hover {
  cursor: context-menu;
  border: 1px solid #ff9900;
}
.activePlan {
  cursor: context-menu;
  border: 1px solid #ff9900;
}
.activePlan .SubscribePlanBtnDiv button {
  cursor: context-menu;
  border: 1px solid #ff9900;
}
.SubscribePlanSecondDiv:hover .SubscribePlanBtnDiv button {
  border: 1px solid #ff9900;
}
.subscribeAll {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(112, 109, 109, 0.502);
  margin-left: -260px;
}
.paymentWindow {
  position: absolute;
  width: 500px;
  height: 224px;
  box-shadow: 10px 10px 20px rgba(112, 109, 109, 0.102);
  background-color: white;
  /* margin: 340px 40%; */
  border-radius: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.paymentMain {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.paymentMainBtns {
  display: flex;
  flex-direction: column;
  height: 224px;
}
.payButton:hover button {
  border: 1px solid #ff9900;
  color: #ff9900;
}
.payButtonFinal {
  margin-top: 0;
}

@media (max-width: 1440px) {
  .SubscribePlanSecond .floatDiv {
    margin: -200px 100px 0 100px;
  }
}

@media (max-width: 1280px) {
  .SubscribePlanSecond .floatDiv {
    margin: -200px 50px 0 50px;
  }
}

@media (max-width: 1024px) {
  .SubscribePlanSecondDiv {
    padding: 10px;
    min-height: 700px;
  }
  .freeDiv {
    min-height: 150px;
  }
  .freeDiv p {
    font-size: 24px;
  }
  .SubListMain p {
    font-size: 15px !important;
  }
  .Lists div {
    margin-bottom: 10px !important;
  }
  .SubscribePlanSecond {
    min-height: 600px;
  }
  .SubscribePlanBtnDiv button {
    font-size: 18px;
    padding: 15px 0;
    width: 100%;
    height: auto;
  }
  .paymentWindow {
    margin: 40px 110px;
  }
  .SubscribePlanBtnDiv button {
    padding: 15px 25px;
  }
  .footerRightBox {
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .freeDiv {
    min-height: 50px;
    padding: 0px 0px 10px 0px;
  }
  .freeDiv p {
    font-size: 20px;
  }
  .SubscribePlanFirst h1 {
    font-size: 28px;
  }
  .SubscribePlanSwitch {
    width: 320px;
  }
  .SubscribePlanSwitch p {
    font-size: 18px;
  }
  .SubscribePlanSecond .floatDiv {
    margin: -300px 30px 0 30px;
  }
  .subscribeAll {
    margin-left: 0;
  }
  .paymentWindow {
    margin: 40px 0px;
  }
}

@media screen and (max-width: 360px) {
  .SubscribePlanSwitch {
    width: 300px;
  }
  .paymentWindow {
    width: calc(100% - 30px);
  }
  .Copyright {
    padding: 0 20px;
  }
}
