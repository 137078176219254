.newp1{
    margin: 24px 0;
    font-weight: bold;
}
.btn{
    font: normal normal 600 18px/27px Poppins;
    color: white;
    background: #FF9900 0% 0% no-repeat padding-box;
    width: 268px;
    height: 46px;
    border-radius: 8px;
    opacity: 1;
    margin-top: 42px;
}
.stepsec{
    flex-direction: column;
    display: flex;
    align-items: center;
} 

.stepsec .smallWindow{
    width: 900px;
}

@media (max-width: 1024px) {
    .stepsec .smallWindow{
        width: 700px;
    }
}