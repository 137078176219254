.TranslateSection {
  margin-top: 32px;
  padding-left: 292px;
  margin-right: 30px;
}
.TranslateSectionFirst {
  max-width: 1200px;
  width: 100%;
  height: 540px;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  
}
.TranslateSectionFirstForm {
  width: 450px;
  height: 400px;
}
.TranslateSectionFirstForm > p {
  font-weight: bold;
}
.TranslateArea {
  width: 450px;
  height: 300px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  border: 1px solid #b2b2b2;
  border-radius: 16px;
}
.TranslateAreaUp {
  width: 100%;
  height: 73px;
  background-color: #F6F6F6;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.TranslateAreaUp > div {
  margin-left: 18px;
  margin-top: 6px;
}
.TranslateAreaDown {
  width: 100%;
  height: 327px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: white;
  padding: 10px;
}
.TranslateSectionFirstMiddle {
  display: flex;
  align-items: center;
}
.TranslateSectionFirstMiddle img{
  cursor: pointer;
}
.TranslateTextArea {
  width: 100%;
  height: 100%;
  resize: none;
  font-size: 20px;
}
.TranslateTextArea p {
  font-size: 20px;
}

@media (max-width: 1280px) {
  .TranslateSectionFirstForm,
  .TranslateArea{
    width: 400px;
  }
}

@media (max-width: 1024px) {
  .TranslateSectionFirstForm,
  .TranslateArea,
  .sel{
    width: 300px;
  }
}

@media (max-width: 768px) {
  .TranslateSection{
    padding-left: 0;
  }
  .TranslateSectionFirst{
    padding: 20px 0px;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .TranslateSectionFirstForm{
    height: auto;
  }
  .TranslateSectionFirstMiddle{
    margin: 30px 0px;
  }
}