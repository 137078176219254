/*
Source
https://codepen.io/akashrajendra/pen/JKKRvQ?editors=1100
*/
.content {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  font-family: "Poppins";
}

.text {
  display: table-cell;
  vertical-align: middle;
}

.text h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  transition: all 0.6s;
  animation: anim 0.5s alternate infinite;
}

@keyframes anim {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
