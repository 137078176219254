@import "./_fonts.scss";

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

button {
  font-family: "Poppins";
  cursor: pointer;
}
input,
textarea,
button {
  font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  font-weight: 600;
}
a,
p,
span {
  font-family: "Poppins";
  font-weight: 500;
}

a {
  text-decoration: none;
}

ul li {
  list-style-type: none;
}

.size {
  max-width: 1560px;
  margin: 0 auto;
  width: 100%;
}

.mainSize {
  width: 1520px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .size {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .size {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .size {
    max-width: 890px;
  }

  .imgHello {
    height: 300px;
    width: 410px;
  }
}

@media screen and (min-width: 1366px) {
  .size {
    max-width: 1120px;
  }
}

@media screen and (min-width: 1440px) {
  .size {
    max-width: 1240px;
  }
}
