.newp1{
    /* tex */
    font-weight: bold;
}
.newdiv{
    width: max-content;
    margin: auto;
}
.sec{
    padding-left: 260px;
    padding-top: 30px;
}
.btndiv{
    display: flex;
}
.btn{
    font: normal normal 600 18px/27px Poppins;
    color: white;
    background: #FF9900 0% 0% no-repeat padding-box;
    width: 268px;
    height: 46px;
    border-radius: 8px;
    opacity: 1;
    margin-top: 42px;
    display: flex;
    margin: 32px auto 0;
    align-items: center;
    justify-content: center;
}
.btn2{
    font: normal normal 600 18px/27px Poppins;
    color: #656565;
    border: 1px solid #656565;
    background: white 0% 0% no-repeat padding-box;
    width: 268px;
    height: 46px;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    margin: 42px auto 0;
    justify-content: center;
}
.a1{
    text-align: center;
    display: block;
    margin-top: 24px;
}
.a1:visited{
    color: black;
}
.StegGet{
    color: #FF9900;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 300px;
}
.CreateStegoSection{
    padding-left: 252px;
}
.CreateStegoSection p {
    text-align: center;
    margin-top: 24px;
    color: #FF9900;
    text-decoration: underline;
    cursor: pointer;
}
.example1Entire{
    width: 100%;
    height: 100%;
    position: absolute;
}


@media (max-width: 768px) {
    .sec,
    .example2sec{
        padding-left: 0 !important;
        text-align: center;
    }

    /* .sidebar-open .sec,
    .sidebar-open .example2sec{
        padding-left: 300px !important;
        margin-right: 30px;
    } */

    .stegoWarningExtract{
        padding-left: 0px !important;
        text-align: center;
    }
    .sidebar-open .stepsec .smallWindow{
        width: 100%;
    }
    .sidebar-open .newdiv{
        width: auto;
    }   
    .stegoWarning{
        margin-bottom: 20px;
    }
}

@media (max-width: 360px) {
    .stepsec .smallWindow{
        width: 100% !important;
        margin: 0 20px;
    }
    .Header2 p{
        font-size: 11px;
    }
    .example2sec{
        margin: 0 20px;
    }

}