#hashLink {
    display: none;
    margin-right: auto;
     margin:1rem;
   
}

#hashLink:target {
    display: block;
    margin:1rem;
}

#hashLink2 {
    display: none;
    margin-right: auto;
     margin:1rem;
   
}

#hashLink2:target {
    display: block;
    margin:1rem;
}
#hashLink3 {
    display: none;
    margin-right: auto;
     margin:1rem;
   
}

#hashLink3:target {
    display: block;
    margin:1rem;
}

.BlogBody{
    width: 100%;
    min-height: 645px;
    display: flex;
    flex-direction: column;
}
.BlogBodyUp{
    width: 100%;
    height: 480px;
    background-image: url('../../assets/images/blog1.jpg');
    display: flex;
    align-items: flex-end;
}
.p1{margin:1rem;display:block;}

.BlogBodyUp1{
    width: 100%;
    height: 480px;
    background-image: url('../../assets/images/blog2.jpg');
    display: flex;
    align-items: flex-end;
}

.BlogBodyUp2{
    width: 100%;
    height: 480px;
    background-image: url('../../assets/images/group_people.png');
    display: flex;
    align-items: flex-end;
}

.BlogBodyUpText{
    height: 90px;
    width: 100%;
    background-color: rgba(34, 31, 31, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.BlogBodyUpText p {
    font-size: 40px;
    font-weight: bold;
    color: white;
}
a {
  color: #FF9900;
    text-align:center;
}


.BlogBodyDown{
    width: 100%;
    height: 100%;
    background-color: rgba(34, 31, 31, 1);
    display: flex;

    justify-content: center;
}
.BlogBodyDown p {
    color: white;
    width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-top: 40px;
}
.BlogBodyDown p:target{
  overflow: visible;
  display:block;
}
@media (max-width: 576px) {
    .BlogBodyUp{
        background-position: center;
        background-size: cover;
    }
    .BlogBodyUp1{
        background-position: center;
        background-size: cover;
    }
    .BlogBodyUp2{
        background-position: center;
        background-size: cover;
    }
    .BlogBody{
        min-height: auto;
    }
    .BlogBodyDown{
        height: auto;
        padding: 20px;
    }
    .BlogBodyDown p{
        margin-top: 0;
            overflow:visible;
    overflow-wrap: break-word;
    word-wrap: break-word;
        font-size: 7px;
        justify-content: center;
        align-items: center;
    }
    .BlogBodyUpText{
    font-size: 14px;
    background-color: rgba(34, 31, 31, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}
    .BlogBodyUpText p {
        font-size: 14px;
        font-weight: bold;
        color: white;
    }

    

    .BlogBodyDown p:target{
    overflow:visible;
    overflow-wrap: break-word;
    word-wrap: break-word;
   width: 200px;
        
    font-size: 7px;
    justify-content: center;
    align-items: center;
}
    
    .BlogBodyDown p p {
    overflow:visible;
    overflow-wrap: break-word;
    word-wrap: break-word;
   width: 200px;
        
    font-size: 7px;
    justify-content: center;
    align-items: center;
}
    
}
