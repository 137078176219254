.WebtoSpeechSection{
    margin-top: 32px;
    padding-left: 292px;
}
.WtSdiv1{
    width: 720px;
    display: flex;
    justify-content: space-between;
}
.emptydiv{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 1540px;
    height: 49px;
    border: 1px solid #B2B2B2;
    border-radius: 16px;
    opacity: 1;
    padding-left: 16px;
}
.emptydivText{
    color: #B2B2B2;
    margin: 12px 16px;
}
.divimg{
    width: 37px;
    padding-right: 16px;
}
.WebToSpeechLoading{
    margin-top: 32px;
}
.nextP{
    margin-top: 35px;
    margin-left: 16px;
    cursor: pointer;
}