.successMessage{
    font-weight: bold;
    color: green;
}

.wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

.footerMenuPages {
    padding-top: 73px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 160px;
}

.footerMenuPages__title {
    color: #f00;
    margin-bottom: 25px;
    margin-top: 25px;
}

.footerMenuPages__ul {
    padding-left: 30px;
}

.footerMenuPages__list {
    list-style-type: inherit;
}

.footerMenuPages__paragraph {
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .footerMenuPages{
        font-size: 14px;
        padding-bottom: 100px;
    }
}