.smallWindow{
    border: 1px solid #B2B2B2;
    border-radius: 16px;
    opacity: 1;
    overflow: hidden;
    width: 100%;
}
.swG{
    min-height: 70px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: rgb(241, 236, 236);
    display:  flex;
    align-items: center;
    justify-content: space-between;
}
.swG > div{
    margin-right: 12px;
    display: flex;
    align-items: center;

    
}
.swG > div:first-child{
    margin: 0 32px;
    
}
.swG > .divlast > p{
    margin-right: 12px;
}
.divlast{
    cursor: pointer;
}


.swW p{
    font-weight: bold;
    position: absolute;
    margin: 32px;
 }
.content{
    font-weight: bold;
    margin-top: 24px;
    margin-left: 36px;
}


.xxx{
    cursor: pointer;
    background-color: #e8e8e8;
    width: 41px ;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .3s ease;
    margin-right: 16px;
}
.xxx:hover{
    background-color: #fce8ca;
}
.longdiv{
    border-radius: 21px;
    width: 138px;
}
.longdiv p {
    margin-left: 6px;
}
.longdiv2{
    background-color: #fce8ca;
    justify-content: center;
    border-radius: 21px;
    width: 106px;
}
.longdiv2 p {
    font-weight: bold;
    opacity: 0.5;
    color: #FF9900;
}
.speechText{
    width: 100%;
    min-height: 500px;
    resize: none;
}