/* TOAST */
.c_toast {
  position: fixed;
  top: 10px;
  right: 20px;
  font-weight: 500;
  border-radius: 6px;
  z-index: 999999;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 10px;
}
.c_toast > div {
  background-color: transparent;
}
