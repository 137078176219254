.signUpSize {
  width: 340px;
  margin: 64px auto;
}
.signUpTitle {
  font-family: PoppinsMedium;
  font-size: 24px;
  margin-bottom: 12px;
}
.signUpText {
  font-family: PoppinsMedium;
  font-size: 16px;
  margin-bottom: 6px;
}
.signupInput {
  width: 100%;
  height: 46px;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  font-size: 16px;
  padding-left: 16px;
  color: #b2b2b2;
  margin-bottom: 40px;
  color: black;
}
.Ireadandaccept {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 12px;
}
.Ireadandaccept input {
  width: 17px;
  height: 17px;
  border: 1px solid #000000;
  border-radius: 4px;
}
.Ireadandaccept p {
  font-size: 14px;
  margin: 0 8px;
}
.Ireadandaccept a {
  font-family: PoppinsSemiBold;
  font-size: 14px;
  text-decoration: underline;
  color: #000;
}
.signUpBtn {
  width: 100%;
  height: 46px;
  background: #ff9900 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  font-family: PoppinsSemiBold;
}
.orsignupwith {
  text-align: center;
  font-size: 14px;
  margin: 18px 0;
}
.Alreadyavenaccount {
  font-size: 14px;
  text-align: center;
}
.Alreadyavenaccount a {
  font-family: PoppinsSemiBold;
  text-decoration: underline;
  color: #000;
  margin-left: 8px;
}
.signUpSocBtns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.signUpSocBtn {
  cursor: pointer;
  width: 158px;
  height: 46px;
  background: #dd4b39 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signUpSocBtn2 {
  background: #3b5998 0% 0% no-repeat padding-box;
}
.signUpSocBtn p {
  color: #ffffff;
  font-size: 16px;
  font-family: PoppinsSemiBold;
  margin-left: 18px;
}
.ForgotPasswordInfo {
  font-family: PoppinsMedium;
  font-size: 16px;
  margin-bottom: 20px;
}
.errorMessage {
  margin-top: -25px;
  font-weight: bold;
  color: red;
}
.errorUl li {
  color: red;
  font-weight: bold;
  text-align: center;
}
.Ireadandaccept input:checked {
  border: 10px solid green;
}
.successSignUp {
  position: absolute;
  width: 250px;
  height: 40px;
  margin-top: 100px;
  margin-left: 20px;
  background-color: white;
  border: 1px solid #ff9900;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.successSignUp p {
  color: #ff9900;
  font-weight: bold;
}
body .react-tel-input {
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  margin-bottom: 36px;
}

body .react-tel-input .selected-flag {
  background-color: transparent !important;
}
body .react-tel-input .form-control {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  width: 100%;
  height: 46px;
  border-radius: 8px;
}

body .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: 0 !important;
}

@media (max-width: 800px) {
  .HeaderPart2 .active {
    display: none !important;
  }
  .footerSize {
    justify-content: center;
  }
}
@media (max-width: 570px) {
  .headerSize {
    justify-content: center;
  }
}
