.SubListMain{
    display: flex;
}
.SubListImg{
    width: 28px;
    height: 28px;
}
.SubListMain p{
    font-weight: bold;
    font-size: 20px;
    margin-left: 12px;
}