.AboutUsBody{
    width: 100%;
    min-height: 645px;
    display: flex;
    flex-direction: column;
}
.AboutUsBodyUp{
    width: 100%;
    height: 480px;
    background-image: url('../../assets/images/group_people.png');
    display: flex;
    align-items: flex-end;
}
.AboutUsBodyUpText{
    height: 90px;
    width: 100%;
    background-color: rgba(34, 31, 31, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.AboutUsBodyUpText p {
    font-size: 40px;
    font-weight: bold;
    color: white;
}
a {
  color: #FF9900;
}


.AboutUsBodyDown{
    width: 100%;
    height: 217px;
    background-color: rgba(34, 31, 31, 1);
    display: flex;
    justify-content: center;
}
.AboutUsBodyDown p {
    color: white;
    width: 1000px;
    text-align: justify;
    margin-top: 40px;
}

@media (max-width: 576px) {
    .AboutUsBodyUp{
        background-position: center;
        background-size: cover;
    }
    .AboutUsBody{
        min-height: auto;
    }
    .AboutUsBodyDown{
        height: auto;
        padding: 20px;
    }
    .AboutUsBodyDown p{
        margin-top: 0;
        font-size: 14px;
    }
}
